/**
 * This file contains functions used to generate field insights stats in the student list view.
 *
*/

export const getFieldStats = data => {
    
    const myData = data
        .filter(record => record !== null)
        .sort((a, b) => a < b ? 1 : -1);

    const recordCount = myData.length;

    const sum = myData.reduce((previous, current) => previous + current, 0)
    const average = (sum / recordCount).toFixed(1);

    const lowMiddle = Math.floor((recordCount - 1) / 2);
    const highMiddle = Math.ceil((recordCount - 1) / 2);
    const median = ((myData[lowMiddle] + myData[highMiddle]) / 2).toFixed(1);
    const min = Math.min(...myData).toString();
    const max = Math.max(...myData).toString();

    return { average, median, min, max };
};
