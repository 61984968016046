/**
    Shows a student's courses. Used in student profile and Tracker.vue.
*/
<template>
<div v-if="courses" class="courses-area" data-cy="courses-area">
    <table class="course-table" v-if="myCourses.length">
        <tr>
            <th v-if="detailed" class="gradeCol">Period</th>
            <th class="courseCol">Course title</th>
            <th class="teacherCol">Teacher</th>
            <th v-if="detailed" class="gradeCol">Credits</th>
            <th v-if="detailed" class="gradeCol">Absences</th>
            <th v-if="detailed" class="gradeCol">Tardies</th>
            <th class="gradeCol" v-for="(markingPeriod, i) in markingPeriods" :key="i">
                {{ markingPeriod }}
            </th>
        </tr>
        <tr v-for="(course, i) in myCourses" :key="i">
            <td v-if="detailed" class="gradeCol">{{ course.period }}</td>
            <td class="courseCol">{{ course.courseTitle }}</td>
            <td class="teacherCol">{{ course.teacher.lastName }}</td>
            <td v-if="detailed" class="gradeCol">{{ course.creditHours }}</td>
            <td v-if="detailed" class="gradeCol">{{ course.currentAbsences }}</td>
            <td v-if="detailed" class="gradeCol">{{ course.currentTardies }}</td>
            <th v-for="(markingPeriod, j) in markingPeriods" 
                :key="j"
                :class="getClass(course.letterGrade[markingPeriod])"
                class="gradeCol"
            >
                {{ getGradeEntry({course, markingPeriod})}}
            </th>
        </tr>
    </table>
    <div class="no-courses-notice" v-else>No courses to show</div>
</div>
</template>

<script>
export default {
    props: {
        courses: Object,
        detailed: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data () {
        return {
            markingPeriods: ['Q1', 'Q2', 'S1', 'Q3', 'Q4', 'S2', 'Y1']
        }
    },
    computed: {
        myCourses: function () {
            const courseKeys = this.courses ? Object.keys(this.courses) : []
            return courseKeys
                .map(key => this.courses[key])
                .filter(course => course !== undefined)
                .sort((a, b) => a.period > b.period ? 1 : -1)
        }
    },
    methods: {
        getClass(letterGrade) {
            if (!letterGrade) return '';
            return 'grade-class-' + letterGrade.slice(0,1);
        },
        getGradeEntry({course, markingPeriod}) {
            if (!course.letterGrade[markingPeriod]) return '';
            return `${course.letterGrade[markingPeriod]} (${course.percentageGrade[markingPeriod].toFixed(0)})`;
        }
    }
}
</script>

<style lang="scss">
@import '../assets/styles/colors.scss';

.courses-area {
    tr:nth-child(even){
        background-color: var(--color-contrast-lower);
    }
    .grade-class-A {
        background-color: var(--color-grade-a);
        color: var(--color-bg);
    }
    .grade-class-B {
        background-color: var(--color-grade-b);
        color: var(--color-bg);
    }
    .grade-class-C {
        background-color: var(--color-grade-c);
        color: var(--color-bg);
    }
    .grade-class-D {
        background-color: var(--color-grade-d);
        color: var(--color-bg);
    }
    .grade-class-F {
        background-color: var(--color-grade-f);
        color: var(--color-bg);
    }
    .grade-class-P {
        background-color: var(--color-grade-p);
        color: var(--color-bg);
    }
}
.no-courses-notice {
    padding: 10px;
}
</style>
