/**
    Insights sidebar component
*/
<template>
    <div class="minichart">
        <div v-if="insightTitle==='ADA & Passing Classes'">
            <Matrix :matrixConfig="insightMatrix" @addMatrixFilter="addMatrixFilter"/>
        </div>
        <div v-else>
            <div class="mini-chart-area" data-cy="mini-chart-area">
                <span 
                    v-for="(dataset, i) in datasets" :key="i"
                    data-cy="mini-bar-chart-block"
                    :class="getClass(dataset)"
                    :style="getStyle(dataset)"
                    @click="addInsightFilter(dataset)"
                >
                    &nbsp;
                </span>
            </div>
            <div class="legend-area">
                <div class="legend-entry" v-for="(dataset, i) in datasets" :key="i">
                    <span
                        :class="getClass(dataset)" class="legend-box" data-cy="mini-chart-legend-box" @click="addInsightFilter(dataset)"
                    >&nbsp;</span>
                    &nbsp;{{ dataset.label }}: {{ dataset.percent }}%
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Matrix from '@/components/matrix.vue';

export default {
    name: 'MiniChart',
    props: {
        datasets: Array,
        insightTitle: String,
        insightMatrix: Object,
    },
    components: {
        Matrix
    },
    emits: [ 'addMatrixFilter', 'addInsightFilter' ],
    methods: {
        getClass(dataset) {
            return `bar-${dataset.style}`;
        },
        getStyle(dataset) {
            return `width: ${dataset.percent}%;`;
        },
        addMatrixFilter(...args) {
            this.$emit('addMatrixFilter', ...args);
        },
        addInsightFilter(dataset) {
            this.$emit('addInsightFilter', dataset.label);
        }
    }
}
</script>

<style lang="scss">
.legend-area, .mini-chart-area {
    margin-top: 15px;
}
.legend-entry {
    margin-top: 2px;
}
.minichart {
    padding: 5px;
    .bar-high-success {
        display: inline-block;
        background-color: var(--color-success);
        &:hover {
            background-color: var(--color-success-light);
        }
    }
    .bar-success {
        display: inline-block;
        background-color: var(--color-success);
        &:hover {
            background-color: var(--color-success-light);
        }
    }
    .bar-gradeB {
        display: inline-block;
        background-color: var(--color-grade-b);
        &:hover {
            background-color: var(--color-success-light);
        }
    }
    .bar-info {
        display: inline-block;
        background-color: var(--color-info);
        &:hover {
            background-color: var(--color-info-light);
        }
    }
    .bar-warning {
        display: inline-block;
        background-color: var(--color-warning);
        &:hover {
            background-color: var(--color-warning-light);
        }
    }
    .bar-contrast-medium {
        display: inline-block;
        background-color: var(--color-contrast-medium);
        &:hover {
            background-color: var(--color-contrast-lowish);
        }
    }
    .legend-box {
        width: 20px;
    }
}
</style>
