/**
 * Contains the config file for the 3x3 G&A matrix.
 */

//TECH DEBT : this configuration needs abstracted out because it is duplicated in draftProtocols.js
export const matrixConfig = {
    columnHeader: 'Passing Classes',
    rowHeader: 'ADA',
    columnFieldKey: 'passingCourseCount',
    rowFieldKey: 'ADA',
    columns: [
        '0-3', 
        '4-5', 
        '6+'
    ],
    rows: [
        '90.1% +', 
        '80.1-90%', 
        '60.1-80%', 
        '0-60%'
    ],
    labels: [
        'Off-Track', 
        'Almost On-Track', 
        'On-Track', 
        'On-Track & High Avg. Daily Attendance'
    ],
    rowFilters: [
        {gte: 90.1},
        {lt: 90, gte: 80.1},
        {lt: 80, gte: 60.1},
        {lt: 60},
    ],
    columnFilters: [
        {lt: 4},
        {lt: 6, gte: 4},
        {gte: 6}
    ],
    cells: [ // calculated later
        [0, 0, 0,],
        [0, 0, 0,],
        [0, 0, 0,],
        [0, 0, 0,]
    ],
    cellClasses: [
        ['problem', 'warning', 'high-success'],
        ['problem', 'warning', 'success'],
        ['problem', 'warning', 'success'],
        ['problem', 'warning', 'success']
    ],
    showChart: true,
    chart: { // calculated later
        data: [],
        fields: [],
        config: {}
    }
};
