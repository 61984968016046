/**
    Field analytics modal (click on the bar chart icon in Student List view - Tracker.vue)
*/
<template>
    <div class="mini-analytics" v-if="fieldKey">
        <div v-if="field.type === 'numeric'">
            <MyChart 
                :chartData="filteredData"
                :chartConfig="chartConfig" 
                ref="miniBar"
            />
            <table>
                <tr>
                    <td>Average:</td>
                    <td>{{ stats.average }}</td>
                    <td>Median:</td>
                    <td>{{ stats.median }}</td>
                </tr>
                <tr>
                    <td>Minimum:</td>
                    <td>{{ stats.min }}</td>
                    <td>Maximum:</td>
                    <td>{{ stats.max }}</td>
                </tr>
                <tr>
                    <td>Students:</td>
                    <td>{{ stats.studentCount }}</td>
                    <td></td>
                    <td></td>
                </tr>
            </table>
        </div>
        <div v-else style="max-width: 400px;">
            <MyChart 
                :chartData="filteredData"
                :chartConfig="chartConfig" 
                ref="miniPie"
            />
            <div class="student-stats">{{ stats.studentCount }} students</div>
        </div>

    </div>
</template>

<script>
import MyChart from '@/components/charts.vue';
import { getFieldStats } from '@/functions/fieldStats.js';
import { getValueFromObject } from '@/functions/utils.js';

export default {
    name: 'MiniAnalytics',
    props: {
        fieldKey: String,
        filteredData: Array
    },
    computed: {
        field: function () {
            return this.config.fields.find(f => f.key == this.fieldKey) || {};
        },
        chartConfig: function () {
            return {
                type: this.field.type === 'numeric' ? 'autoHistogram' : 'analyticsPie',
                title: this.field.displayName,
                primaryField: this.field,
                showAsPercentage: false,
                selectedFormatOption: 'Show count and percentage',
                onClick: () => {},
                filters: [],
            };
        },
        stats: function () {
            const studentCount = this.filteredData.length;
            if (this.field.type !== 'numeric') return { studentCount };
            const myData = this.filteredData.map(record => getValueFromObject(record, this.field.key));
            let result = {studentCount, ...getFieldStats(myData)};

            //Check to see if we calculated any numbers that should display as a percentage. Put a % label after the number if found.
            if (this.field.displayType && this.field.displayType === 'percentage' ) {
                Object.keys(result).forEach(key => {
                    result[key] = key !== 'studentCount'? result[key] + '%' : result[key];
                });
            }
            return result;
        }
    },
    components: { MyChart }
}
</script>

<style lang="scss">
.mini-analytics {
    .student-stats {
        text-align: center;
    }
    table {
        background-color: var(--color-bg);
        padding: 8px;
        border-radius: 8px;
        margin-left: auto;
        margin-top: 10px;
        margin-right: auto;
        td {
            padding-right: 10px;
        }
    }
}
</style>
