/**
    Component that interacts with a user's views and sets the current view in the Tracker.vue table.
*/
<template>
    <Modal data-cy="add-new-view-modal" ref="addView" title="Add new view" instructions="Enter name of custom view" @success="addView">
        <input class="spaced-input" v-model="modals.addView" placeholder="Type here">
    </Modal>
    <Modal data-cy="rename-view-modal" ref="renameView" title="Rename view" instructions="Enter name of custom view" @success="renameView">
        <input class="spaced-input" v-model="modals.renameView" placeholder="Type here">
    </Modal>
    <div class="sidebar-section">
        <div class="sidebar-section-title">Views</div>
        <div class="sidebar-section-padded" data-cy="views-sidebar-option">
            <CustomSelect 
                style="display: inline-block;"
                :customWidth="185"
                :options="views" 
                displayField="name"
                v-model="selectedFocus" 
                @mouseover="showTooltip('Choose a view from the dropdown menu.')"
                @mouseleave="showTooltip('')"
            />
            <Popout data-cy="views-popout">
                <div
                    @click="this.$refs.addView.show()" 
                    @mouseover="showTooltip('Save the current view so you can return to it later.')"
                    @mouseleave="showTooltip('')"
                >
                    Save Current View
                </div>
                <div 
                    v-if="showingCustomView" 
                    @click="updateView()" 
                    @mouseover="showTooltip('Update the currently selected view to match the current table layout.')"
                    @mouseleave="showTooltip('')"
                >
                    Update View
                </div>
                <div
                    v-if="showingCustomView"
                    @click="this.$refs.renameView.show()"
                    @mouseover="showTooltip('Rename the current view.')"
                    @mouseleave="showTooltip('')"
                >
                    Rename View
                </div>
                <div
                    v-if="selectedFocus!==''"
                    @click="setViewAsDefault()"
                    @mouseover="showTooltip('Set the current view as default. It will show when you open the app.')"
                    @mouseleave="showTooltip('')"
                >
                    Set View as Default
                </div>
                <div
                    class="warning"
                    v-if="showingCustomView"
                    @click="deleteView()"
                    @mouseover="showTooltip('Delete the current view from this dropdown menu.')"
                    @mouseleave="showTooltip('')"
                >
                    Delete View
                </div>
            </Popout>
            <CustomButton data-cy="choose-columns" @click="$emit('launchUpdateColumns')" buttonText="Choose Columns" />
            <CustomButton @click="$emit('download')" buttonText="Download" />
        </div>
    </div>
</template>

<script>
import { defaultViews } from '@/apps/views.js';
import Popout from '@/components/tracker/popout.vue';
import Modal from '@/components/modals/modal.vue';
import CustomSelect from '@/components/customSelect.vue';
import CustomButton from '@/components/customButton.vue';

export default {
    props: {
        customViews: Array
    },
    data() {
        return {
            selectedFocus: '',
            modals: {
                addView: '',
                renameView: '',
            }
        }
    },
    components: {
        Popout, CustomSelect, Modal, CustomButton
    },
    emits: ['applyView', 'addView', 'updateView', 'renameView', 'notify', 'deleteView', 'launchUpdateColumns', 'download'],
    computed: {
        views: function () {
            // combines custom views and default views to create a list of all views
            return this.customViews
                .map(obj => {
                    obj.optionGroup = 'Custom Views';
                    return obj;
                })
                .concat(
                    defaultViews
                        .filter(view => view.grades.includes(this.store.state.showingGradeLevel))
                        .map(obj => {
                            obj.optionGroup = 'Standard Views';
                            return obj;
                        })
                        .sort((a, b) => a.name > b.name ? 1 : -1)
                );
        },
        showingCustomView: function () {
            // decide if the current selected view is a custom view
            const myView = this.views.find(element => element.name == this.selectedFocus);
            if (!myView) return false;
            if (!myView.custom) return false;
            return true;
        },
    },
    watch: {
        selectedFocus: function () {
            this.$emit('applyView', {selectedFocus: this.selectedFocus, views: this.views});
        }
    },
    methods: {
        setSelectedFocus(name) {
            this.selectedFocus = name;
        },
        avoidDuplicate({str, views}) { 
            // prevent saving view with duplicate name
            
            const duplicateExists = str => views.find(element => element.name == str) ? true : false;

            if (duplicateExists(str)) {
                let foundUnique = false, counter = 0, newStr = '';
                while (!foundUnique) {
                    counter++;
                    newStr = str + '-' + counter.toString();
                    foundUnique = !duplicateExists(newStr);
                }
                return newStr
            } else {
                return str;
            }
        },
        processViewName({
            name, 
            views=this.views, 
            avoidDuplicate=this.avoidDuplicate
        }) {
            let result = name || 'My Custom View';
            result = result.trim();
            result = avoidDuplicate({str: result, views});
            return result;
        },
        setView(name) {
            this.selectedFocus = name;
            this.saveViews();
        },
        addView() { 
            // add user-defined view // called when closing 'addView' modal window
            const name = this.processViewName({name: this.modals.addView});
            this.$emit('addView', name);
            this.setView(name);
        },
        renameView() {
            // rename user defined view // called when closing 'renameView' modal window
            const name = this.processViewName({name: this.modals.renameView});
            const oldName = this.selectedFocus;
            this.$emit('renameView', {oldName, name});
            this.setView(name);
       },
        updateView() {
            // update user defined view to current table layout
            this.$emit('updateView', this.selectedFocus);
            this.saveViews();
        },
        setViewAsDefault() {
            this.fb.user.updatePreference('defaultView', this.selectedFocus);
            this.notify('Current view set as default');
        },
        notify(str) {
            this.$emit('notify', str);
        },
        deleteView() {
            // delete user defined view
            this.$emit('deleteView', this.selectedFocus);

            const currentView = this.customViews.find(element => element.name === this.selectedFocus);
            const saveObj = this.customViews.filter(view => view !== currentView);

            const needToResetDefaultView = this.$user.preferences.defaultView === currentView.name;
            // reset view to default if one is set, or to config default
            this.selectedFocus = needToResetDefaultView ?
                'Standard':
                this.$user.preferences.defaultView || 'Standard';

            // reset user default view if necessary
            if (needToResetDefaultView) {
                this.fb.user.updatePreference('defaultView', 'Standard');
            }

            this.saveViews({saveObj});
            this.notify('View deleted');
        },
        saveViews({saveObj=this.customViews}={}) {
            // serialize customviews object ready to save
            const thingToSave = JSON.stringify(saveObj);
            this.fb.user.updatePreference('customViews', thingToSave);
        }
    }
}
</script>
